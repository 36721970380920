let ldc = new(function() {

    jQuery.easing['jswing'] = jQuery.easing['swing'];

    easeOutQuad = function (x, t, b, c, d){
		var s=1.70158;var p=0;var a=c;
		if (t==0) return b;  if ((t/=d)==1) return b+c;  if (!p) p=d*.3;
		if (a < Math.abs(c)) { a=c; var s=p/4; }
		else var s = p/(2*Math.PI) * Math.asin (c/a);
		return -(a*Math.pow(2,10*(t-=1)) * Math.sin( (t*d-s)*(2*Math.PI)/p )) + b;
	}

    this.menuMb = function() {
        $('body').toggleClass('noScroll');
    }

    this.menuOnMovil = function(){
        $('.ldc-nav a').on('click', function(e){
            e.preventDefault();
            console.log('clic');
            let enlace = $(this).attr('href');
            $('.ldc-nav a').removeClass('current');
            $(this).addClass('current');
            setTimeout(function () {
                $(".hamburger").trigger('click');
                $('html, body').animate({
                    scrollTop: $(enlace).offset().top - $('header').outerHeight()
                }, 1000, 'swing');
            }, 500);
        });
    }

    this.menuOnDesk = function(){
        $('.ldc-nav a').on('click', function(e){
            e.preventDefault();
            let enlace = $(this).attr('href');
            $('.ldc-nav a').removeClass('current');
            $(this).addClass('current');
            $('html, body').animate({
                scrollTop: $(enlace).offset().top - ($('header').outerHeight())
            }, 2000);
        });
    }

    this.menuChanges = function(mql){
        if (mql.matches){
            ldc.menuOnMovil();
        } else{
            ldc.menuOnDesk();
        }
    }

    // scroll

    this.onScroll = function(){
        $(window).on('scroll',function () {
            if ($(window).scrollTop() > 30)
                $('header').addClass('sticky')
            else
                $('header').removeClass('sticky')
        })
    }

    this.init = function(){
        const mbMenu =window.matchMedia("(max-width: 767px)");
        ldc.menuChanges(mbMenu);
        mbMenu.addEventListener("change", ldc.menuChanges);
        ldc.onScroll();
        $('.hamburger').on('click', ldc.menuMb);
    }
});

(function($) {
    ldc.init();
})();